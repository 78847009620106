import {BREAKPOINTS} from "@/styles/themeSettings";
import Error from "next/error";
import {CMS_ELEMENT_TYPENAME, CONTENT_TYPES, PADDING, PAGES, SINGLE_PAGE_TYPES} from "@/constants";

const BREAKPOINT_WIDTHS = {
	[BREAKPOINTS.LARGE_DESKTOP]: 2560,
	[BREAKPOINTS.DESKTOP]: 1920,
	[BREAKPOINTS.TABLET]: 1024,
	[BREAKPOINTS.MOBILE]: 375,
};
export function getResponsiveValue(value = 20, breakpoint = BREAKPOINTS.DESKTOP){
	const width = BREAKPOINT_WIDTHS[breakpoint] || BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP];
	return precise(value / width);
}

export function getCssSizeValue(value , breakpoint) {
	if (!value) throw new Error('Value is not defined');
	if (typeof value !== 'number') throw new Error('Value is not a number');
	const vWidth = precise(getResponsiveValue(value, breakpoint) * 100) //multiply to get css vw
	const rem = precise(value / 16);
	return `min(${vWidth}vw, ${rem}rem)`;
}

export function getFontSize(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
	const css = getCssSizeValue(value, breakpoint);
	return `calc(${css} * var(--typoSize, 1))`;
}

//handles CMS redactor fields
export function getHtmlFromRedactorField(field) {
	if (!field) {
		return null;
	}
	return { __html: `${field}` };
}

export const animate = (variants) => {
	return {
		initial: "initial",
		exit: "exit",
		animate: "enter",
		variants
	}
}
function removeTrailingSlashes(input) {
	return input.replace(/\/+$/, '');
}
export function removeTags(input, tags) {
	let output = input;
	tags.forEach((tag) => {
		const regex = new RegExp(`<\/?${tag}(\\s*\\/?)?>`, 'g');
		output = output.replace(regex, '');
	});
	return output;
}

export function getItemType(item, fallback=''){
	return item?.[CMS_ELEMENT_TYPENAME] || fallback;
}

function slugify(slug=''){
	if(!isString(slug)) {
		console.warn('slug is not a string!')
		return ''
	}
	let trimmedSlug = slug.trim()
	if(trimmedSlug === '/'){
		return trimmedSlug
	}
	return removeTrailingSlashes(trimmedSlug)
}
export function getWWDActionUrl(action){
	if (!action){
		throw new Error('wwd action is not defined!')
	}
	return slugify(`/${PAGES.WHAT_WE_DO}/${action?.slug || ''}`)
}
export function getWWDCategoryUrl(category){
	if (!category){
		throw new Error('wwd category is not defined!')
	}
	const prefix = category?.slug !== PAGES.WHAT_WE_DO ? `/${PAGES.WHAT_WE_DO}/` : '/'
	return slugify(`${prefix}${category?.slug || ''}`)
}
export function getNewsUrl(news){
	if (!news){
		throw new Error('news is not defined!')
	}
	const slug = news?.slug || ''
	const prefix = news?.slug === 'aktualnosci' ? `/${PAGES.POLISH_RED_CROSS}/` : `/${PAGES.POLISH_RED_CROSS}/${PAGES.NEWS}/`
	return slugify(`${prefix}${slug}`)
}

export function getSingleBWUUrl(singleBWU){
	if (!singleBWU){
		throw new Error('singleBWU is not defined!')
	}
	return slugify(`/${PAGES.BE_WITH_US}/${singleBWU?.category?.slug || ''}/${singleBWU?.slug || ''}`)
}
export function getBWUUrl(bwu){
	if (!bwu){
		throw new Error('bwu is not defined!')
	}
	return slugify(`/${bwu?.slug || ''}`)
}

export function getPaymentUrl(payment){
	if (!payment){
		throw new Error('Payment is not defined!')
	}
	const id = getIdWithoutSpecialSymbols(payment?.id || '')
	return slugify(`/${PAGES.I_PAY}#${id}`)
}

export function getProductUrl(product){
	if (!product){
		throw new Error('Product is not defined!')
	}
	const slug = product?.slug || ''
	return slugify(`/${PAGES.POLISH_RED_CROSS}/${PAGES.PRODUCTS}/${slug}`)
}

export function getFactUrl(fact){
	if (!fact){
		throw new Error('Fact is not defined!')
	}
	const slug = fact?.slug || ''
	return slugify(`/${PAGES.POLISH_RED_CROSS}/${PAGES.PROJECTS_AND_FACTS}/${slug}`)
}
export function getArticleUrl(article){
	if (!article){
		throw new Error('Article is not defined!')
	}
	const slug = article?.slug || ''
	return slugify(`/${PAGES.ARTICLES}/${slug}`)
}

export function getAnnouncementYearURl(announcement){
	if (!announcement){
		throw new Error('Announcement Year is not defined!')
	}
	const slug = announcement?.slug || ''
	return slugify(`/${PAGES.OTHERS}/${PAGES.ANNOUNCEMENTS_AND_TENDERS}/${slug}`)
}
export function getDescriptionWithImageUrl(item){
	if (!item){
		throw new Error('DescriptionWithImage is not defined!')
	}
	const isBeWithUsPage = item?.isBeWithUsPage || false
	const page = isBeWithUsPage ? PAGES.BE_WITH_US : PAGES.WHAT_WE_DO;
	const id = item?.id || ''
	return slugify(`/${page}#${id}`)
}
export function getSinglePageUrl(page){
	if (!page){
		throw new Error('page is not defined!')
	}
	let prefix = '/'
	let slug = page?.slug || ''
	const pageType = page?.pageType || SINGLE_PAGE_TYPES.SINGLE
	switch (pageType){
		case SINGLE_PAGE_TYPES.SINGLE:
			prefix = '/'
			break;
		case SINGLE_PAGE_TYPES.OTHERS:
			if(slug === PAGES.OTHERS) break; //skip prefix for pozostale
			prefix = `/${PAGES.OTHERS}/`
			break;
		case SINGLE_PAGE_TYPES.LOOKING_FOR_HELP:
			if(slug === PAGES.LOOKING_FOR_HELP) break; //skip prefix for looking-for-help
			prefix = `/${PAGES.LOOKING_FOR_HELP}/`
			break;
		case SINGLE_PAGE_TYPES.POLISH_RED_CROSS:
			prefix = `/${PAGES.POLISH_RED_CROSS}/`
			break;
		case SINGLE_PAGE_TYPES.ANNOUNCEMENTS:
			prefix = `/`
			break;
		default:
			prefix = '/'
			break;
	}
	let url = slugify(`${prefix}${slug}`) //final

	//overwrite for home
	if(slug === 'home'){
		url = '/'
	}
	return url
}

export function getUrlFunctionFromItem(item, debug=false){
	const type = getItemType(item)
	if(type.length <= 0) {
		// console.warn('Item has no typename', item, type)
	}
	switch (type){
		case CONTENT_TYPES.WHAT_WE_DO_SINGLE_ACTION:
			return getWWDActionUrl
		case CONTENT_TYPES.WHAT_WE_DO_CATEGORY:
			return getWWDCategoryUrl
		case CONTENT_TYPES.ALL_NEWS_PAGE:
			return getNewsUrl
		case CONTENT_TYPES.NEWS:
			return getNewsUrl
		case CONTENT_TYPES.SINGLE_BE_WITH_US_PAGE:
			return getSingleBWUUrl
		case CONTENT_TYPES.SINGLE_PAGE:
			return getSinglePageUrl
		case CONTENT_TYPES.SINGLE_PAYMENT:
			return getPaymentUrl
		case CONTENT_TYPES.DESCRIPTION_WITH_IMAGE:
			return getDescriptionWithImageUrl
		case CONTENT_TYPES.BE_WITH_US_PAGE:
			return getBWUUrl
		case CONTENT_TYPES.ANNOUNCEMENTS_AND_TENDERS_YEARS:
			return getAnnouncementYearURl
		case CONTENT_TYPES.PRODUCT_PAGE:
			return getProductUrl
		case CONTENT_TYPES.FACT_PAGE:
			return getFactUrl
		case CONTENT_TYPES.ARTICLE:
			return getArticleUrl
		default:
			if(debug){
				console.warn('Unknown item type:', type)
			}
			return () => ''
	}
}

export function getItemUrl(item, debug=false){
	const format = getUrlFunctionFromItem(item, debug)
	return format(item)
}


export function getPaddingClass(padding, side='top'){
	if(padding === PADDING.NONE) return ''
	const prefix = `p${side.slice(0,1)}`
	return padding ? `${prefix}-${padding}` : '';
}
export function getPaddingsClassFromData(data) {
	const paddingTop = data?.paddings?.top || PADDING.LARGE;
	const paddingBottom = data?.paddings?.bottom || PADDING.LARGE;
	const paddingTopClass = getPaddingClass(paddingTop, 'top')
	const paddingBottomClass = getPaddingClass(paddingBottom, 'bottom')
	return `${paddingTopClass} ${paddingBottomClass}`;
}



function setter(domNode, object) {
	return Object.entries(object).forEach(([key, value]) => {
		domNode?.style.setProperty(`--${key}`, value);
	})
}

//applies variables to the element or an array of elements
//e.x. setCssVars(SomeRef.current, { color: 'red', opacity: 1 })
//e.x. setCssVars([FirstRef.current, SecondRef.current], { color: 'red', opacity: 1 })
//effect: --color: red, --opacity: 1
export function setCssVars(target, vars = {}) {
	if (!target || typeof vars !== 'object' || Object.keys(vars).length <= 0) return;
	if (Array.isArray(target)) return target.forEach((arrEl) => setter(arrEl, vars));
	return setter(target, vars);
}
export function partition(array=[], filter) {
	return array.reduce(
		([pass, fail], elem, index, array) => {
			return filter(elem, index, array) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
		},
		[[], []],
	);
}

export const validateEmail = (email) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const mergeArrays = (a, b, predicate = (a, b) => a === b) => {
	const c = [...a]; // copy to avoid side effects
	// add all items from B to copy C if they're not already present
	b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
	return c;
}

export function omit(keys, obj) {
	const result = {...obj};
	keys.forEach((key) => {
		delete result[key];
	});
	return result;
}

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
export function lowercaseFirstLetter(string) {
	return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getAspectRatioFromImage(image) {
	const width = image?.width || 0;
	const height = image?.height || 1;
	return getAspectRatio(width, height)
}

export function getAspectRatio(width, height) {
	return precise(width / height === 0 ? 16/9 : width / height)
}

export function logTable(object = {}) {
	const table = {}
	Object.entries(object).forEach(([key, value]) => table[key] = value)
	return console.table(table)
}

//useful for testing, pass variable in an object like this: { myVar: 0.1 }
//expected return: console.log('myVar:', 0.1)
export function logNamed(obj) {
	Object.entries(obj).forEach(([key, value]) => console.log(key + ":", value))
}
export function getIdWithoutSpecialSymbols (id) {
	return id ? id.replace(/[^a-zA-Z ]/g, "") : '';
}

export function removeBranchFromAsPath(asPath) {
	let [path, queryString = ""] = asPath.split("?");
	if (queryString) {
		const queryParams = queryString.split("&").filter(q => !q.startsWith("branch="));
		if (queryParams.length) {
			return `${path}?${queryParams.join("&")}`;
		}
	}
	return path;
}

export function removeAllQueryParamsFromAsPath(asPath) {
	let [path] = asPath.split("?");
	return path;
}

export function isReactRef(obj) {
	return obj && typeof obj === 'object' && 'current' in obj;
}

export function isString(value) {
	return typeof value === 'string';
}
export function isFunction(value) {
	return typeof value === 'function';
}
export function isUndefined(value) {
	return typeof value === 'undefined';
}

export function isNumber(value) {
	return typeof value === 'number';
}

export function isObject(value) {
	return typeof value === 'object';
}

export function formatContentWithRecap(data){
	if (!Array.isArray(data)) return null;

	return data.map((el) => {
		const { item, ...rest } = el
		return ({
			...rest,
			...item
		})
	})
}

export function isStringEmpty(str) {
	return !str || str.trim().length === 0;
}

export function clamp(value, floor, ceil) {
	return Math.min(Math.max(value, floor), ceil);
}


export function getCarouselIndex(index, array){
	return [(index + array.length - 1) % array.length, (index + 1) % array.length] // [prev, next]
}

//util to easily set map item key
export function getMapKey(item, fallback){
	return item?.id || fallback
}

export function getMapKey2(item, index){
	return item?.id + index || index
}

//returns float number with given precision, cut's trailing zeros
export function precise(x, precision=5) {
	return parseFloat((x).toFixed(precision))
}

export function getCurrentYear() {
	const currentDate = new Date(Date.now());
	const currentYear = currentDate.getFullYear();
	return currentYear;
}

export function groupArrayBySize(arr, groupSize) {
	const result = [];

	for (let i = 0; i < arr.length; i += groupSize) {
		result.push(arr.slice(i, i + groupSize));
	}

	return result;
}

export async function copyToClipboard(value, debug=false){
	let state = 'rejected'
	if(!navigator){
		console.error('navigator doesnt exist')
		return state
	}
	if(!navigator.clipboard){
		console.error('navigator.clipboard doesnt exist')
		return state
	}
	await navigator.clipboard.writeText(value).then(() => {
		if(debug){
			console.log('fulfilled')
		}
		state = 'success'
	}, () => {
		if(debug){
			console.error('rejected')
		}
		state = 'rejected'
	}).catch((err) => {
		console.error(err)
		state = 'error'
	})
	return state
}

export function getMonthNames(locale = 'pl') {
	const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
	const months = [];

	for (let i = 0; i < 12; i++) {
		const date = new Date(2000, i, 1); // Use a fixed year for consistency
		months.push(formatter.format(date));
	}

	return months;
}

// Throttle helper to limit update frequency (optional)
export function throttle(func, limit) {
	let lastFunc;
	let lastRan;
	return function (...args) {
		if (!lastRan) {
			func(...args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(function () {
				if (Date.now() - lastRan >= limit) {
					func(...args);
					lastRan = Date.now();
				}
			}, limit - (Date.now() - lastRan));
		}
	};
}

//returns deep cloned object to prevent unintentional mutations
export function deepClone(obj) {
	if (obj === null || typeof obj !== 'object') {
		throw new Error('Object is not an object type');
	}
	return JSON.parse(JSON.stringify(obj));
}

//returns interpolated value between a & b with a delta of n
export function lerp(a, b, n) {
	return (1 - n) * a + n * b;
}

export function getSeparatePaddingClasses(data) {
	const top = data?.paddings?.top || PADDING.LARGE;
	const bottom = data?.paddings?.bottom || PADDING.LARGE;
	const paddingTop = getPaddingClass(top, 'top')
	const paddingBottom = getPaddingClass(bottom, 'bottom')

	return { paddingTop, paddingBottom }
}

export function fetcher(args){
	const [url, body] = args
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	}).then(res => res.json())
}

export function getHoverClass(hovering) {
	return hovering ? 'hover' : ''
}

export function getPropSafely(data, variable, fallback){
	return data?.[variable] || fallback || ''
}

export const handleSetPositionOnMouseMove = (e) => {
	const rect = e.currentTarget.getBoundingClientRect();
	const x = ((e.clientX - rect.left) / rect.width) * 100;
	const y = ((e.clientY - rect.top) / rect.height) * 100;
	e.currentTarget.style.setProperty('--x', `${x}%`);
	e.currentTarget.style.setProperty('--y', `${y}%`);
};

export function getMultipleLocalStorageItems(keysArray = []) {
	let itemsObject = {};
	keysArray.forEach(key => {
		let value = localStorage.getItem(key)
		if (value !== null) {
			itemsObject[key] = value;
		}
	});

	return itemsObject;
}
